import React, {createContext, useEffect, useState} from 'react';
import io from 'socket.io-client';
import {HOST} from "./config";

export const SocketContext = createContext();

export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const [sid, setSid] = useState(null);

  useEffect(() => {
    const newSocket = io(HOST);

    newSocket.on('connect', () => {
      setSid(newSocket.id);
      console.log('Session ID:', newSocket.id);
    });

    setSocket(newSocket);

    return () => {
      newSocket.disconnect();
    };
  }, []);

  return (
    <SocketContext.Provider value={{ socket, sid }}>
      {children}
    </SocketContext.Provider>
  );
};