import React, {useEffect, useState, useContext} from 'react';
import {downloadVideoFromYouTube, fileUpload} from "./services";
import Data from "./Data";
import {HOST} from "./config";
import {SocketContext} from "./Socket";


const App = () => {
  const [videoUrl, setVideoUrl] = useState('');
  const [videoRef, setVideoRef] = useState('');
  const [error, setError] = useState('');
  const [progress, setProgress] = useState(null);
  const context = useContext(SocketContext);

  useEffect(() => {
    console.log('Socket Context: ', context)
    if (context.socket) {
      context.socket.on('progress_download', ({progress}) => {
        console.log('Progress: ', progress);
        if (progress) setProgress(Math.round(Number(progress)));
      });

      return () => {
        context.socket.off('dataReceived');
      };
    }
  }, [context]);

  function refreshStore() {
    setError('');
    setVideoRef('');
    setProgress(null);
  }

  const handleSubmitDownloadYouTube = async (e) => {
    e.preventDefault();
    refreshStore();
    setProgress(0);

    const response = await downloadVideoFromYouTube(videoUrl, context.sid);
    if (response.success) {
      setProgress(null);
      setVideoRef(HOST + response.videoPath);
    } else {
      setProgress(null);
      setError(response.error || 'Server error')
    }
  };

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;
    const formData = new FormData();
    formData.append('video', file);

    refreshStore();

    const response = await fileUpload(formData, setProgress);

    if (response.success) {
      setVideoRef(HOST + response.videoPath);
    } else {
      setError(response.error || 'Server error')
    }
  };

  return (
    <div className="App">
      <div className="App-header">
        <h1>Enter direct video URL</h1>
        <form onSubmit={handleSubmitDownloadYouTube}>
          <input
            type="text"
            placeholder="Enter YouTube video URL"
            value={videoUrl}
            onChange={(e) => setVideoUrl(e.target.value.replace('https://www.youtube.com/watch?v=', 'https://youtu.be/'))}
          />
          <button type="submit">Upload video</button>
        </form>
        <h1>or choose file from local</h1>
        <div className="fileUpload">
          <label htmlFor="videofile">Choose File</label>
          <input type="file" id="videofile" accept="video/*" name="videofile" onChange={handleFileUpload}/>
        </div>
        {!videoRef && progress !== null && (
          <p>Uploading video...  {progress} %</p>
        )}
        {error && (
          <>
            <h1 className='error'>Failed</h1>
            <h1 className='error'>{error}</h1>
          </>
        )}
      </div>
      <Data videoRef={videoRef} setError={setError}/>
    </div>
  );
};

export default App;
