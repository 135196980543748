import axios from 'axios';
import {HOST, PORT} from "./config";


export async function downloadVideoFromYouTube(videoUrl, sid) {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    };

    const response = await axios.post(
      HOST + PORT + '/rest/download-youtube',
      { url: videoUrl, sid },
      config
    );

    const { videoPath } = response.data;
    return { success: true, videoPath };
  } catch (err) {
    console.error('Error downloadVideoFromYouTube:', err.response.data.error);
    switch (err.response.data.error) {
      case 'Download video exited with code 1':
        return { success: false, error: 'Video is not exist' };
      default:
        return { success: false, error: err.response.data.error };
    }
  }
}


export async function fileUpload(formData, setProgress) {
  try {
    const response = await axios.post(HOST + PORT + '/rest/fileUpload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress: progress => {
        const { total, loaded } = progress;
        const uploadPercentage = (loaded / total) * 100;
        setProgress(Math.round(uploadPercentage));
      },
    });

    const {videoPath} = response.data;
    //console.log('?????', videoPath)
    return {success: true, videoPath};
  } catch (err) {
    console.error('Error fileUpload:', err.response.data.error);
    switch (err.response.data.error) {
      case 'Download video exited with code 1':
        return {success: false, error: 'Video is not exist'};
      default:
        return {success: false, error: err.response.data.error};
    }
  }
}

export async function cutIntoChapters(videoRef, sid, script) {
  try {
    const url = videoRef.match(/\/tmp.+/)[0];
    const response = await axios.post(HOST + PORT + '/rest/cut',
      {url, sid, script},
      {headers: {'Content-Type': 'application/x-www-form-urlencoded'}}
    );

    return {success: true, data: response.data};
  } catch (err) {
    console.error('Error cut into chapters:', err.response.data.error);
    switch (err.response.data.error) {
      case 'Download video exited with code 1':
        return {success: false, error: 'Video is not exist'};
      default:
        return {success: false, error: err.response.data.error};
    }
  }
}

export async function makeScreenshots(videoRef, secondsArray) {
  try {
    const url = videoRef.match(/\/tmp.+/)[0];
    const response = await axios.post(HOST + '/rest/makeScreenshots',
      {
        url: url,
        secondsArray: secondsArray
      },
      {headers: {'Content-Type': 'application/x-www-form-urlencoded'}}
    );

    console.log(response);
    return {success: true, data: response.data};
  } catch (err) {
    console.error('Error make screenshots:', err.response.data.error);
    return {success: false, error: err.response.data.error};
  }
}


export function timeNormalizer(timeStamps, separate = [":", ":", ""]) {
  const h = Math.floor(timeStamps / 3600);
  const m = Math.floor((timeStamps - h * 3600) / 60);
  const s = Math.floor(timeStamps - h * 3600 - m * 60);

  return `${h ? h + separate[0] : ''}${m.toString().length < 2 ? '0' + m : m}${separate[1]}${s.toString().length < 2 ? '0' + s : s}${separate[2]}`;
}

export function createRoadMapOfIndexes(arr1, arr2) {
  const roadMap = [];
  const arrTemp = [...arr2];

  for (const t1 of arr1) {
    const intermediateRoadMap = [];
    const i1 = arr1.indexOf(t1)
    console.log('i: ', t1)

    //Find nearest value
    const nearestValue = arrTemp.reduce((acc, t2, i) => {
      if (acc) return acc;

      if (t1 - 1 <= t2 && t2 - 1 <= t1) {
        const i2 = arr2.indexOf(t2)
        //console.log('The nearest value: ', arr2[i2]);
        //arrTemp.splice(t2, 1);
        delete arrTemp[i];
        acc = [i1, i2];
        return acc;
      }
    }, null);

    //Find value less than current
    arrTemp.forEach((t2, i) => {
      if (t1 - 1 > t2) {
        const i2 = arr2.indexOf(t2);
        //console.log('Value less: ', arr2[i2]);
        intermediateRoadMap.push([null, i2]);
        //arrTemp.splice(t2, 1);
        delete arrTemp[i];
      }
    });

    if (nearestValue) {
      intermediateRoadMap.push(nearestValue)
    } else {
      intermediateRoadMap.push([i1, null])
    }

    if (intermediateRoadMap.length > 0) {
      intermediateRoadMap.forEach(irm => roadMap.push(irm));
    }
  }

  //Add last elements from second array
  if (arrTemp.length > 0) {
    arrTemp.forEach(t2 => {
      const i2 = arr2.indexOf(t2);
      roadMap.push([null, i2]);
    });
  }

  return roadMap;
}


export const readFile = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      resolve(event.target.result);
    };
    reader.onerror = (error) => {
      reject(error);
    };
    reader.readAsText(file);
  });
};