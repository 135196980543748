import React, {useState, useRef, useEffect, useContext} from 'react';
import {createRoadMapOfIndexes, cutIntoChapters, makeScreenshots, timeNormalizer, readFile} from "./services";
import {v4 as uuidv4} from 'uuid';
import {HOST} from "./config";
import {SocketContext} from "./Socket";
const FFMPEG = 'FFMPEG';
const AI = 'AI';


const Data = (props) => {
  const [thumbnails, setThumbnails] = useState([]);
  const [timeStamps, setTimeStamps] = useState([]);
  const [thumbnailsFromFile, setThumbnailsFromFile] = useState([]);
  const [timeStampsFromFile, setTimeStampsFromFile] = useState([]);
  const [unionResults, setUnionResults] = useState([]);
  const [benchmark, setBenchmark] = useState(null);
  const [progress, setProgress] = useState(null);
  const [sortOfScript, setSortOfScript] = useState(AI);
  const videoElement = useRef(null);
  const context = useContext(SocketContext);

  useEffect(() => {
    console.log('Socket Context in Data.jsx: ', context)
    if (context.socket) {
      context.socket.on('progress_cut', ({progress}) => {
        console.log('Progress: ', progress);
        if (progress) setProgress(Math.round(Number(progress)));
      });

      return () => {
        console.log('dataReceived in Data.jsx',);
        context.socket.off('dataReceived');
      };
    }
  }, [context]);


  useEffect(() => {
    console.log('useEffect: ', props.videoRef)
    if (!props.videoRef) {
      setThumbnails([]);
      setTimeStamps([]);
      setThumbnailsFromFile([]);
      setTimeStampsFromFile([]);
      setUnionResults([]);
      setBenchmark(null);
    }

  }, [props.videoRef]);

  useEffect(() => {
    try {
      if (thumbnails.length && thumbnailsFromFile.length) {
        const union = [];

        const roadMap = createRoadMapOfIndexes(
          timeStamps.map(t => parseFloat(t)),
          timeStampsFromFile.map(t => parseFloat(t))
        )

        console.log('roadMap: ', roadMap)
        for (const [i1, i2] of roadMap) {
          const tempRoadMap = []
          if (i1 === null) {
            tempRoadMap.push(null, null)
          } else {
            tempRoadMap.push(timeStamps[i1], thumbnails[i1])
          }

          if (i2 === null) {
            tempRoadMap.push(null, null)
          } else {
            tempRoadMap.push(timeStampsFromFile[i2], thumbnailsFromFile[i2])
          }

          union.push(tempRoadMap);
        }

        console.log('UNION', union);
        return setUnionResults(union);
      }
    } catch (e) {
      console.error('Error UNION', e);
    }
  }, [thumbnails, timeStamps, thumbnailsFromFile, timeStampsFromFile]);

  const handleSubmitCutIntoChapters = async () => {
    if (!props.videoRef) return;
    props.setError('');
    setProgress('preparation');
    setThumbnails([]);
    setTimeStamps([]);
    const response = await cutIntoChapters(props.videoRef, context.sid, sortOfScript);

    if (response.success) {
      setProgress(null);
      const {screenshots, timeStamps, benchmark} = response.data;

      setThumbnails(screenshots.map(s => HOST + s));
      setTimeStamps(timeStamps);
      setBenchmark(benchmark);
    } else {
      setProgress(null);
      props.setError(response.error || 'Server error');
    }
  }


  const handleChaptersUpload = async (e) => {
    await setThumbnailsFromFile([]);
    await setTimeStampsFromFile([]);

    const file = e.target.files[0];

    if (file) {
      const fileContent = await readFile(file);
      if (!fileContent) return props.setError('Error reading file of timestamps');

      const arrayTimeStamps = fileContent
        .trim()
        .split('\n')
        .map(line => (line / 1000).toFixed(3));

      console.log('arrayTimeStamps: ', arrayTimeStamps)

      const response = await makeScreenshots(props.videoRef, arrayTimeStamps);

      if (response.success) {
        console.log(response.data);
        await setTimeStampsFromFile(arrayTimeStamps);
        await setThumbnailsFromFile(response.data.map(s => HOST + s));
      } else {
        props.setError(response.error || 'Server error');
      }
    }
  }

  const handleThumbnailClick = (time) => {
    if (videoElement.current) {
      videoElement.current.currentTime = time;
    }
  };

  const handleChooseScript = (event) => {
    if (event.target.checked) {
      setSortOfScript(FFMPEG)
    } else {
      setSortOfScript(AI)
    }
  };

  const handleDownloadChapters = () => {
    const data = timeStamps.reduce((acc, timeStamp) => {
      acc += `${timeStamp * 1000}\n`;
      return acc
    }, '');

    const blob = new Blob([data], {type: 'text/plain;charset=utf-8'});
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    const videoId = props.videoRef
      .replace('.mp4', '')
      .split('/')
      .pop();

    link.download = `${videoId}.txt`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  function renderThumbnail(timestamp, image) {
    return image === null ? (
      <div className="row" key={uuidv4()}>
        <p className="x">X</p>
      </div>
    ) : (
      <div className="row" key={uuidv4()} onClick={() => handleThumbnailClick(timestamp)}>
        <img src={image}/>
        <a href="#">{timeNormalizer(timestamp)}</a>
      </div>
    );
  }


  return (
    <div className="content">
      {props.videoRef && (
        <div className="video">
          <video
            ref={videoElement}
            controls
            width="600"
            height="350"
            src={props.videoRef}
          ></video>
          <div className="buttons">
            <button type="submit" onClick={() => handleSubmitCutIntoChapters(props.videoRef)}>Cut into chapters</button>
            <label htmlFor="ChaptersUpload">Upload Chapters</label>
            <input type="file" accept=".txt" name="ChaptersUpload" id="ChaptersUpload"
                   onChange={handleChaptersUpload}/>
          </div>
          <div className="btn-container">
            <label className="switch btn-color-mode-switch">
              <input type="checkbox"
                     name="color_mode"
                     id="color_mode"
                     value="1"
                     data-on="FFMPEG"
                     data-off="AI"
                     onClick={handleChooseScript}/>
              <label htmlFor="color_mode" data-on="FFMPEG" data-off="AI"
                     className="btn-color-mode-switch-inner"></label>
            </label>
          </div>


          {benchmark && (
            <p className="benchmark">Execution time script {timeNormalizer(benchmark, ['h', 'm', 's'])} on intel dual
              core</p>
          )}
          {
            progress !== null && (
              <p className="progress-cut">Process cutting... {progress} {progress === 'preparation' ? '' : "%"}</p>
            )
          }
        </div>
      )}

      {
        thumbnails.length > 0 && thumbnailsFromFile.length === 0 && (
          <div className="container">
            <div className="info">
              <p className="title">{thumbnails.length - 1} chapters from API</p>
              <a className="title" href="#" onClick={() => handleDownloadChapters()}>Download Chapters</a>
            </div>
            < div className="list">
              {thumbnails.length &&
                thumbnails.map((thumbnail, index) => (
                  <div className="row" key={index} onClick={() => handleThumbnailClick(timeStamps[index])}>
                    <img src={thumbnail}/>
                    <a href="#">{timeNormalizer(timeStamps[index])}</a>
                  </div>
                ))}
            </div>
          </div>
        )
      }

      {
        thumbnails.length === 0 && thumbnailsFromFile.length > 0 && (
          <div className="container">
            <div className="info">
              <p className="title">{thumbnailsFromFile.length - 1} chapters from file</p>
            </div>
            < div className="list">
              {thumbnailsFromFile.length &&
                thumbnailsFromFile.map((thumbnail, index) => (
                  <div className="row" key={index} onClick={() => handleThumbnailClick(timeStampsFromFile[index])}>
                    <img src={thumbnail}/>
                    <a href="#">{timeNormalizer(timeStampsFromFile[index])}</a>
                  </div>
                ))}
            </div>
          </div>
        )
      }

      {
        unionResults.length > 0 && (
          <div className="container">
            <div className="bar">
              <p className="first title">{thumbnails.length - 1} chapters from API</p>
              <a className="second title" href="#" onClick={() => handleDownloadChapters()}>Download
                Chapter</a>
              <p className="third title">{thumbnailsFromFile.length - 1} chapters from file</p>
            </div>
            <div className="list">
              {unionResults.map(([$1, $2, $3, $4], index) => (
                <div className="union-row" key={uuidv4()}>
                  <React.Fragment key={uuidv4()}>
                    {renderThumbnail($1, $2)}
                    {renderThumbnail($3, $4)}
                  </React.Fragment>
                </div>
              ))}
            </div>
          </div>
        )
      }
    </div>
  )
}

export default Data;